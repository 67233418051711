import { type BaseData, MainSubStructureModel, RenderByPermission, request } from '@/utils';
import { Button, Modal, Switch, message } from 'antd';
import { action, observable } from 'mobx';
import { API } from './api';
import GroupListModel from './groupList/model';
import InsertUserModel from './insertUser/model';
export default class WeComAIModel {
  @observable public pageSize: number;
  @observable public pageNumber: number;
  constructor() {
    this.weComAIgrid.onQuery();
  }

  public groupListStore = new GroupListModel({ parent: this });
  public insertUserStore = new InsertUserModel({ parent: this });

  public weComAIgrid: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增机器人用户',
        permissionId: '2',
        handleClick: () => {
          this.insertUserStore.onShow();
        },
      },
    ],
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),

    grid: {
      columns: [
        {
          key: 'operation',
          name: '操作',
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                {/* <RenderByPermission permissionId={`${pageId}_`}> */}
                <Button
                  type="link"
                  onClick={() => {
                    this.groupListStore.onShow(row);
                  }}
                >
                  微信群列表
                </Button>
                {/* </RenderByPermission> */}
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.onDeleteCategoryHandler(row.sysUserInfo.sysUserId);
                    }}
                  >
                    删除
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_141`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.updateGroupBySysUserId(row.sysUserInfo.sysUserId);
                    }}
                  >
                    更新
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'sysUserInfo',
          name: '用户信息',
          formatter: ({ row }) => {
            const { sysUserInfo } = row;
            const { sysUserId, mobile, sysUserName } = sysUserInfo;
            return (
              <div style={{ height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>用户昵称：{sysUserName}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>ID：{sysUserId}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>手机号：{mobile}</div>
              </div>
            );
          },
        },
        {
          key: 'paymentNotice',
          name: '主播付费通知',
          formatter: ({ row }) => (
            <div style={{ textAlign: 'left' }}>
              <Switch
                checked={row.paymentNotice === 1}
                checkedChildren="启用"
                unCheckedChildren="禁用"
                onChange={() => {
                  this.onSwitchChange(row);
                }}
              />
            </div>
          ),
        },
        {
          key: 'createUserInfo',
          name: '创建人',
          formatter: ({ row }) => {
            const { createUserInfo } = row;
            const { sysUserId, mobile, sysUserName } = createUserInfo;
            return (
              <div style={{ height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>用户昵称：{sysUserName}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>ID：{sysUserId}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>手机号：{mobile}</div>
              </div>
            );
          },
        },
        {
          key: 'createTime',
          name: '创建时间',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      rowHeight: 80,
      primaryKeyField: 'createUserInfo',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const { ...rest } = params;

        this.pageSize = rest.pageSize;
        this.pageNumber = rest.pageNumber;
        return request<BaseData<any>>({
          url: API.WechatUserList,
          method: 'post',
          data: { ...rest },
        });
      },
    },
  });

  @action public onSwitchChange = async (data) => {
    const { sysUserInfo } = data;
    try {
      return await request<BaseData<any>>({
        url: API.updatePaymentNotice,
        method: 'post',
        data: {
          sysUserId: sysUserInfo.sysUserId,
        },
      });
    } finally {
      this.weComAIgrid.onRefresh();
    }
  };

  private onDeleteCategoryHandler = (id: string) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除该机器人用户吗',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({ url: API.deleteCorpWechatUser, method: 'post', data: { sysUserId: id } });
        message.success('删除成功');
        this.weComAIgrid.onQuery();
      },
    });
  };

  private updateGroupBySysUserId = async (id: string) => {
    try {
      const resp = await request<BaseData<any>>({
        url: API.updateGroupBySysUserId,
        method: 'POST',
        data: {
          id,
        },
      });
      if (resp.code === 200) {
        message.success('更新成功');
      }
    } finally {
      this.weComAIgrid.onRefresh();
    }
  };
}
